.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#big-img {
  max-width: 90%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.social-share-icons {
  padding: 10px;
}

.social-share-icons button {
  margin-right: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
button:focus {
  outline: none !important;
}

.primary-button-extra-small {
  font-size: 10px !important;
  padding: 0 10px !important;
  height: 23px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-extra-small:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-extra-small:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button-small {
  font-size: 12px !important;
  padding: 0 12px !important;
  height: 27px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-small:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-small:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button {
  font-size: 14px !important;
  padding: 0 14px !important;
  height: 32px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button-large {
  font-size: 16px !important;
  padding: 0 16px !important;
  height: 36px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-large:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-large:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.secondary-button {
  min-width: fit-content !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border-bottom: none !important;
  color: #000000 !important;
  line-height: 16px !important;
  border-bottom: 1px solid #000000 !important;
}
.secondary-button:hover {
  border-bottom: 1px solid #000000 !important;
  padding: 2px 0 !important;
  font-weight: bold;
  line-height: 14px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 1px solid #000000 !important;
}

.image-gallery-thumbnail {
  width: 100px;
}

.image-gallery-thumbnail img {
  width: auto;
  height: 75px;
}

.image-gallery-slide img {
  width: 100%;
  height: 200px;
}
